import React from 'react';
import { gql } from '@apollo/client';
import {
  Datagrid,
  Pagination,
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  ReferenceManyField,
  NumberField,
  TabbedShowLayoutTabs,
  SimpleShowLayout,
} from 'react-admin';
import DateTimeField from 'components/DateTimeField';
import RevenueAdjustmentsTab from 'components/tabs/RevenueAdjustmentsTab';
import ExpenseAdjustmentsTab from 'components/tabs/ExpenseAdjustmentsTab';
import JobAside from './JobAside';
import JobShowActions from './ShowActions';
import useCheckAccess from 'hooks/useCheckAccess';
import DisputesTab from 'components/tabs/DisputesTab';
import JobProfileTab from 'components/tabs/JobProfileTab';
import InfoTab from './tabs/InfoTab';
import HistoryTab from './tabs/HistoryTab';

const JobsShow = () => {
  const checkAccess = useCheckAccess();

  return (
    <Show aside={<JobAside />} actions={<JobShowActions />} data-testid="jobs-show">
      <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" />}>
        <Tab label="Info" data-testid="job_show-info_tab">
          <InfoTab />
        </Tab>
        {checkAccess('view', 'Break') && (
          <Tab label="Breaks" path="breaks" data-testid="job_show-breaks_tab">
            <SimpleShowLayout>
              <ReferenceManyField
                pagination={<Pagination />}
                label=""
                reference="Break"
                target="job_id"
              >
                <Datagrid bulkActionButtons={false} data-testid="job_show-breaks_table">
                  <TextField label="ID" source="id" data-testid="job_show-breaks_id" />
                  <DateTimeField
                    label="Starts Time"
                    source="starts_time"
                    tzSource="job.location.timezone_lookup"
                  />
                  <NumberField
                    label="Duration Minutes"
                    source="duration_minutes"
                    data-testid="job_show-breaks_duration_minutes"
                  />
                </Datagrid>
              </ReferenceManyField>
            </SimpleShowLayout>
          </Tab>
        )}
        {checkAccess('view', 'RevenueAdjustment') && (
          <Tab
            label="Revenue adjustments"
            path="revenue-adjustments"
            data-testid="job_show-revenue_adjustments_tab"
          >
            <RevenueAdjustmentsTab target="job_id" />
          </Tab>
        )}
        {checkAccess('view', 'ExpenseAdjustment') && (
          <Tab
            label="Expense adjustments"
            path="expense-adjustments"
            data-testid="job_show-expense_adjustments_tab"
          >
            <ExpenseAdjustmentsTab target="job_id" />
          </Tab>
        )}
        {checkAccess('view', 'Dispute') && (
          <Tab label="Disputes" path="disputes" data-testid="job_show-disputes_tab">
            <DisputesTab target="job_id" />
          </Tab>
        )}
        {checkAccess('view', 'JobProfile') && (
          <Tab label="Job Profiles" path="profiles" data-testid="job_show-rate-info_tab">
            <JobProfileTab />
          </Tab>
        )}
        {checkAccess('view', 'JobBilling') && checkAccess('view', 'Audit') && (
          <Tab label="History" path="history" data-testid="job_show-history_tab">
            <HistoryTab target="job_id" />
          </Tab>
        )}
      </TabbedShowLayout>
    </Show>
  );
};

JobsShow.query = gql`
  query GET_ONE($id: Int!) {
    job(id: $id) {
      id
      professional_id
      professional {
        id
        account {
          id
          agency_id
          company_id
        }
      }
      timecard_id
      location_id
      shift_type
      created_at
      location {
        id
        timezone_lookup
        market {
          id
          display_name
          state {
            id
            name
            covid_requirement
          }
        }
        state {
          id
          name
          covid_requirement
        }
        covid_requirement
        name
        company {
          id
        }
      }
      starts_time
      callback_ends_time
      on_call_ends_time
      ends_time
      invoices {
        id
      }
      payouts {
        id
      }
      status
      approval_status
      positions {
        id
        display_name
      }
      payout_bonus_cents
      starts_date
      favorite_ids
      is_w2
      ends_date
      callback_starts_time
      callback_ends_time
      on_call_starts_time
      on_call_ends_time
      approved_clock_in
      approved_clock_out
      specialties {
        id
        label
      }
      surgery_types {
        id
        display_name
      }
      patient_population
      available_professional_count
      release_to_platform
      release_audiences
      rate_calculator {
        id
      }
      job_billings {
        payout {
          id
        }
      }
      job_billing_sums {
        payout_regular_hours
        payout_overtime_hours
        payout_double_hours
        total_on_call_hours
        payout_total_amount_cents
        charge_regular_hours
        charge_overtime_hours
        charge_double_hours
        charge_total_amount_cents
      }
      marketplace_job_profile {
        bonus_one_time_cents
        charge_base_hourly_rate_cents
        payout_base_hourly_rate_cents
        payout_facility_bonus_cents
      }
      charge_base_hourly_rate_cents
      payout_base_hourly_rate_cents
      bonus_one_time_cents
      payout_facility_bonus_cents
      expense_adjustment_total_cents
      revenue_adjustment_total_cents
      detail
      parking_reimbursement_cents
      offer_source
      scrubs_provided
      covid_care
      track_unpaid_breaks
      attachment
      clinical_contact_account_id
      clinical_contact {
        first_name
        last_name
        phone_number
      }
      last_cancellation {
        reason
        note
        other_reason
      }
      updated_at
      booked_at
      canceled_by_location
      canceled_by_professional
      extended_by_location
      extended_by_professional
      professional_can_cancel
      updated_in_cancellation_window
      cancellation_fee_window
      cancellation_fee_charge_hours
      guaranteed_minimum_hours
      assignment {
        id
      }
      admin_notes
      override_minimum
      override_rates
      suspicious
      facility_number
      cleared_no_show
      billing_group_id
      breaks {
        id
        starts_time
        duration_minutes
      }
      posting_field_contents
      condition_ids
      optional_condition_ids
      reserved_for_favorites_only
      job_audits {
        id
        auditable_id
        auditable_type
        associated_id
        associated_type
        audited_changes
        action
        created_at
      }
      pro_confirmed_at
      expense_adjustments {
        payout {
          id
        }
      }
    }
  }
`;

export default JobsShow;
