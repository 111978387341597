import React, { ApolloClient, ApolloProvider, from, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import config from 'config';
import { createContext, useContext } from 'react';
import { useTokenStorage } from 'hooks/useTokenStorage';
import { useLocation, useSearchParams } from 'react-router-dom';

const REACT_APP_COMMIT_SHA = process.env.REACT_APP_COMMIT_SHA;

type ApolloClientContext = {
  client: ApolloClient;
};

const apolloClientContext = createContext<ApolloClientContext>({
  client: null,
});

export const ApolloClientContextProvider = ({ children }) => {
  const { getToken } = useTokenStorage();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const uri = `${config.graphql_url}/graphql`;

  const pathname = searchParams.toString()
    ? `${location.pathname}?${searchParams}`
    : location.pathname;

  const contextLink = setContext(async (_, { headers }) => {
    const storedToken = await getToken();
    return {
      headers: {
        ...headers,
        authorization: storedToken ? `Bearer ${storedToken}` : undefined,
        frontend_version: REACT_APP_COMMIT_SHA ?? 'unkown',
      },
    };
  });

  const extensionsLink = new HttpLink({
    uri,
    fetch: async (uri, options) => {
      if (options?.body) {
        const body = JSON.parse(options.body.toString());
        body.extensions = {
          ...body.extensions,
          pathname,
        };
        options.body = JSON.stringify(body);
      }
      return fetch(uri, options);
    },
  });

  const client = new ApolloClient({
    uri,
    cache: new InMemoryCache(),
    link: from([
      contextLink,
      new RetryLink(),
      extensionsLink,
      new HttpLink({
        uri,
        fetchOptions: {
          mode: 'cors',
          signal: new AbortController().signal,
        },
      }),
    ]),
  });

  return (
    <apolloClientContext.Provider value={{ client }}>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </apolloClientContext.Provider>
  );
};

export const useApolloClientContext = () => useContext(apolloClientContext);
