import List from 'components/List';
import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField, TextInput } from 'react-admin';
import IdField from 'components/IdField';
import IdInput from 'components/Filter/IdInput';

const ConditionFilters = [
  <IdInput />,
  <TextInput alwaysOn source="label" />,
  <TextInput alwaysOn source="slug" />,
  <TextInput alwaysOn source="condition_type" />,
];

const ConditionsList = () => (
  <List filters={ConditionFilters} exporter={false}>
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <TextField source="label" />
      <TextField source="slug" />
      <TextField source="condition_type" />
      <TextField source="payload" sortable={false} />
    </Datagrid>
  </List>
);

ConditionsList.query = gql`
  query GET_CONDITIONS_LIST($input: ConditionQueryInput) {
    conditions(input: $input) {
      id
      label
      slug
      condition_type
      payload
      qualifications {
        id
        long_name
        qualification_type
      }
    }
    _conditionsMeta(input: $input) {
      count
    }
  }
`;

export default ConditionsList;
