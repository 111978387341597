import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  BooleanInput,
  DateTimeInput,
  AutocompleteInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  FormDataConsumer,
  required,
} from 'react-admin';
import ReferenceInput from 'components/ReferenceInput';
import AccountManagerInput from 'components/Filter/AccountManagerInput';
import SalesAssociateInput from 'components/Filter/SalesAssociateInput';
import MoneyInput from 'components/MoneyInput';
import useCheckAccess from 'hooks/useCheckAccess';
import RestrictedInput from 'components/RestrictedInput';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import StateInput from 'components/StateInput';
import MarketInput from 'components/MarketInput';
import CompanyInput from 'components/CompanyInput';
import isNil from 'lodash/isNil';
import isFinite from 'lodash/isFinite';

const transformPayload = (payload) => ({
  ...payload,
  primary_billing_group_id: payload.billing_group_ids?.[0],
  parking_cost_cents: payload.parking_free ? 0 : payload.parking_cost_cents,
});

const LocationsCreate = () => {
  const checkAccess = useCheckAccess();

  return (
    <Create redirect="show" data-testid="locations-create" transform={transformPayload}>
      <SimpleForm
        sanitizeEmptyValues
        defaultValues={{
          maximum_late_clock_out_grace_period_minutes: 240,
          approval_free_late_clock_out_grace_period_minutes: 5,
        }}
      >
        <CompanyInput validate={[required()]} />
        <FormDataConsumer>
          {({ formData }) => {
            const hasCompany = !!formData.company_id;
            return (
              <ReferenceArrayInput
                source="billing_group_ids"
                reference="BillingGroup"
                filter={{ company_id: formData.company_id, status: 'active' }}
                disabled={!hasCompany}
                enableGetChoices={() => hasCompany}
              >
                <AutocompleteArrayInput
                  optionText="name"
                  disabled={!hasCompany}
                  sx={{ minWidth: 256 }}
                />
              </ReferenceArrayInput>
            );
          }}
        </FormDataConsumer>
        <TextInput label="Name" source="name" validate={[required()]} />
        <ReferenceInput
          reference="LocationType"
          source="location_type_id"
          emptyText="Clear"
          validate={[required()]}
        >
          <AutocompleteInput optionText="label" />
        </ReferenceInput>
        <TextInput source="phone_number" />
        <TextInput source="about" />
        <TextInput source="address_first" />
        <TextInput source="address_second" />
        <TextInput source="address_city" />
        <TextInput source="address_zip" />
        <StateInput source="state_id" validate={[required()]} />
        <MarketInput />
        <RestrictedInput
          action="create"
          component={SalesAssociateInput}
          source="sales_associate_id"
          emptyText="Clear"
        />
        <RestrictedInput
          action="create"
          component={AccountManagerInput}
          source="account_manager_id"
          emptyText="Clear"
        />
        {/* RestrictedInput not currently working with DateTimeInput */}
        {checkAccess('update', 'Location', 'last_time_job_posted') && (
          <DateTimeInput source="last_time_job_posted" />
        )}
        <TextInput source="detailed_notes" />
        <BooleanInput source="scrubs_provided" />
        <RestrictedInput
          action="create"
          component={BooleanInput}
          source="drug_screening_required"
        />
        <RestrictedInput
          action="create"
          component={BooleanInput}
          source="require_physical_signature"
        />
        <RestrictedInput action="create" component={TextInput} source="timezone_lookup" />
        <NumberInput source="billable_orientation_hours" />
        <NumberInput source="non_billable_orientation_burden_hours" />
        <MoneyInput source="parking_reimbursement_cents" />
        <TextInput source="parking_instructions" />
        <BooleanInput source="parking_free" />
        <BooleanInput source="parking_reimbursed" />
        <BooleanInput source="parking_validated" />
        <FormDataConsumer>
          {({ formData }) => {
            const isParkingFree = !!formData.parking_free;

            if (isParkingFree) {
              return <MoneyInput source="parking_cost_cents" format={() => '$0.00'} disabled />;
            }

            return <MoneyInput source="parking_cost_cents" />;
          }}
        </FormDataConsumer>
        <BooleanInput source="allow_per_diem_jobs" />
        <BooleanInput source="allow_assignments" />
        <RestrictedInput
          action="create"
          component={NumberInput}
          options={{ maximumFractionDigits: 10 }}
          source="coordinates.latitude"
        />
        <RestrictedInput
          action="create"
          component={NumberInput}
          options={{ maximumFractionDigits: 10 }}
          source="coordinates.longitude"
        />
        <RestrictedInput action="create" component={NumberInput} source="cancellation_fee_window" />
        <RestrictedInput
          action="create"
          component={NumberInput}
          source="cancellation_fee_charge_hours"
        />
        <RestrictedInput
          action="create"
          component={NumberInput}
          source="guaranteed_minimum_hours"
        />
        <RestrictedInput
          action="create"
          component={NumberInput}
          source="facility_cancellation_hours"
        />
        <RestrictedInput
          action="create"
          component={NumberInput}
          source="start_clock_in_early_window_minutes"
          validate={(value, formValues) => {
            if (isNil(value)) {
              return;
            }

            if (!isFinite(value)) {
              return 'Must enter a valid number';
            }

            if (value < 0) {
              return 'Cannot be less than 0';
            }

            if (value < formValues.clock_in_early_window) {
              return 'Must be greater than or equal to clock_in_early_window';
            }
          }}
        />
        <RestrictedInput
          action="create"
          component={NumberInput}
          source="clock_in_early_window"
          validate={(value, formValues) => {
            if (isNil(value)) {
              return;
            }

            if (!isFinite(value)) {
              return 'Must enter a valid number';
            }

            if (value < 0) {
              return 'Cannot be less than 0';
            }

            if (value > formValues.start_clock_in_early_window_minutes) {
              return 'Must be less than or equal to start_clock_in_early_window_minutes';
            }
          }}
        />
        <RestrictedInput
          label="Maximum Clock Out Period"
          action="create"
          component={NumberInput}
          source="maximum_late_clock_out_grace_period_minutes"
          min={0}
        />
        <RestrictedInput
          label="Approval Free Grace Period"
          action="create"
          component={NumberInput}
          source="approval_free_late_clock_out_grace_period_minutes"
          min={0}
        />
        <RestrictedInput
          label="Common Shift Length Hours"
          action="create"
          component={NumberInput}
          source="common_shift_length_hours"
          min={0}
        />
        <RestrictedInput
          action="create"
          component={BooleanInput}
          label="Allow pro to adjust start/end times"
          source="professional_can_adjust_times"
        />
        <RestrictedInput
          action="create"
          component={BooleanInput}
          source="enable_geo_fence"
          defaultValue={true}
        />
        <RestrictedInput
          action="create"
          component={NumberInput}
          source="geo_fence_radius"
          min={300}
        />
        <RestrictedInput
          action="create"
          component={BooleanInput}
          label="Require 530 on Clock Out"
          source="require_upload_on_clock_out"
        />
        <RestrictedInput
          action="create"
          component={BooleanInput}
          label="Require photo on clock in"
          source="require_facility_timecard_system_photo_on_clock_in"
        />
        <RestrictedInput
          action="create"
          component={BooleanInput}
          label="Require photo on clock out"
          source="require_facility_timecard_system_photo_on_clock_out"
        />
        <RestrictedInput
          action="create"
          component={BooleanInput}
          source="ignore_signature_when_creating_meal_break"
        />
        <EnumSelectInput emptyText="None" sort="" source="covid_requirement" />
        <RestrictedInput
          action="create"
          component={NumberInput}
          label="Minimum Years Of Experience"
          source="min_years_of_experience"
        />
        <RestrictedInput
          action="create"
          component={NumberInput}
          label="Mileage Requirement"
          source="mileage_requirement"
        />
        <RestrictedInput
          action="create"
          component={BooleanInput}
          source="allow_weekly_overtime_settings"
        />
        <RestrictedInput
          action="create"
          component={BooleanInput}
          source="facility_managed_agency_enabled"
        />
        <TextInput source="external_id" label="External ID" />
      </SimpleForm>
    </Create>
  );
};

export default LocationsCreate;
