import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer } from '@mui/material';
import React, { useEffect } from 'react';
import {
  useGetList,
  FormDataConsumer,
  Labeled,
  NumberInput,
  useInput,
  useRecordContext,
} from 'react-admin';
import ProfessionalQualificationAttributesInputValues from './ProfessionalQualificationAttributesInputValues';

const ProfessionalQualificationAttributesInput = ({
  source = 'professional_qualification_attributes_attributes',
  label = 'Professional Qualification Attributes',
  inputSource = ['admin', 'user', 'system', 'agency', 'user_automation'],
}) => {
  const [_qualificationId, setQualificationId] = React.useState(null);

  const record = useRecordContext();
  const proQualificationAttributes =
    useGetList(
      'ProfessionalQualificationAttribute',
      {
        filter: {
          professional_qualification_id: record?.id,
          search: {
            qa_active: true,
            qa_input_sources: ['admin', 'user', 'system', 'agency', 'user_automation'],
          },
        },
      },
      { enabled: Boolean(record?.id) },
    ).data ?? [];

  return (
    <Labeled label={label} fullWidth isRequired>
      <FormDataConsumer>
        {({ formData }) => {
          const {
            field: { onChange },
          } = useInput({ source });

          const hasQualification = !!formData.qualification_id;
          const { data: qualificationAttributes = [] } = useGetList(
            'QualificationAttribute',
            {
              filter: {
                qualification_id: formData.qualification_id,
                active: true,
                search: {
                  input_sources: inputSource,
                },
              },
            },
            {
              enabled: hasQualification,
            },
          );

          useEffect(() => {
            setQualificationId((prevQualificationId) => {
              if (
                prevQualificationId !== null &&
                prevQualificationId !== formData.qualification_id
              ) {
                onChange([]);
              }

              return formData.qualification_id;
            });
          }, [formData.qualification_id, onChange]);

          if (!hasQualification) {
            return <div>Select a qualification</div>;
          }

          const sortedQualificationAttributes = (() => {
            if (record?.id) {
              const recordAttributes = (
                formData?.[source]?.map((item) => item.qualification_attribute) ?? []
              ).filter((attribute) => Boolean(attribute?.id));
              const recordAttributesIds = recordAttributes.reduce(
                (acct, attribute) => ({
                  ...acct,
                  [attribute.id]: true,
                }),
                {},
              );
              const nonRecordAttributes = qualificationAttributes.filter(
                (attribute) => !recordAttributesIds[attribute.id],
              );
              return [...recordAttributes, ...nonRecordAttributes];
            }

            return qualificationAttributes;
          })();

          return (
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Field Type</TableCell>
                    <TableCell>Slug</TableCell>
                    <TableCell>Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedQualificationAttributes.map((qualificationAttribute, index) => {
                    const placeholderProQualificationAttribute = {
                      qualification_attribute: qualificationAttribute,
                    };

                    const proQualificationAttribute = proQualificationAttributes.find(
                      ({ qualification_attribute_id }) =>
                        qualification_attribute_id === qualificationAttribute.id,
                    );

                    return (
                      <TableRow key={qualificationAttribute.id}>
                        <TableCell>
                          <NumberInput
                            source={`${source}.${index}.qualification_attribute_id`}
                            style={{ display: 'none' }}
                            label="ID"
                            value={qualificationAttribute.id}
                            defaultValue={qualificationAttribute.id}
                            disabled
                          />
                          {qualificationAttribute.id}
                        </TableCell>
                        <TableCell>{qualificationAttribute.name}</TableCell>
                        <TableCell>{qualificationAttribute.field_type}</TableCell>
                        <TableCell>{qualificationAttribute.slug}</TableCell>
                        <TableCell>
                          <ProfessionalQualificationAttributesInputValues
                            source={source}
                            qualificationAttribute={qualificationAttribute}
                            proQualificationAttribute={
                              proQualificationAttribute ?? placeholderProQualificationAttribute
                            }
                            index={index}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          );
        }}
      </FormDataConsumer>
    </Labeled>
  );
};

export default ProfessionalQualificationAttributesInput;
