import { useRecordContext } from 'react-admin';
import MoneyField from 'components/MoneyField';
import { Grid, Typography } from '@mui/material';
import React from 'react';
import isEqual from 'lodash/isEqual';

export const MarketplaceJobProfileInheritedField = (props: { source: string }) => {
  /**
   * If the job has null values, we default to show the 'marketplace' job_profile rates.
   * Shifts with release_audiences === ['saas'] do not have a marketplace job_profile
   * This can be made more generic in the future without hard coding 'Marketplace'
   *  but for now there are no other use cases.
   */
  const job = useRecordContext();
  const { source } = props;

  const jobValue = job[source];
  const jobSource = source;
  const marketplaceJobProfileSource = `marketplace_job_profile.${jobSource}`;

  if (jobValue !== null) {
    return <MoneyField source={jobSource} />;
  } else if (isEqual(job.release_audiences, ['saas'])) {
    return <Typography variant="body2">Internal Posting</Typography>;
  } else {
    return (
      <Grid container spacing={1}>
        <Grid item>
          <MoneyField source={marketplaceJobProfileSource} />
        </Grid>
        <Grid item>
          <Typography variant="body1">(Marketplace Rate)</Typography>
        </Grid>
      </Grid>
    );
  }
};
