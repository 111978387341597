import React, { useRef } from 'react';
import ReferenceArrayInput from 'components/ReferenceArrayInput';
import {
  Create,
  AutocompleteArrayInput,
  SimpleForm,
  TextInput,
  NumberInput,
  FormDataConsumer,
  SelectInput,
  required,
  AutocompleteInput,
  NullableBooleanInput,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { omit } from 'ramda';
import DateInput from 'components/DateInput';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import DateTimeInput from 'components/DateTimeInput';
import PositionInput from 'components/PositionInput';
import SpecialtyInput from 'components/SpecialtyInput';
import Heading from 'components/Heading';
import MoneyInput from 'components/MoneyInput';
import DaysSelect from 'components/DaysSelect';
import { createConditionalInputWrapper } from 'hoc/createConditionalInputWrapper';
import JobBreaks from './JobBreaks';
import OverrideRates from './OverrideRates';
import JobTimeInfoSection from './JobTimeInfoSection';
import ClinicalContactInput from 'components/ClinicalContactInput';
import useCheckAccess from 'hooks/useCheckAccess';
import useLocation from 'hooks/useLocation';
import ReferenceInput from 'components/ReferenceInput';
import InputUtils from 'utils/input';
import ProfessionalInput from 'components/ProfessionalInput';
import LocationInput from 'components/LocationInput';
import PostingFields from 'components/PostingFields';

const getLocation = (id) => {
  const { data: location } = useLocation(id);
  return location;
};
const HideIfAssignmentJob = createConditionalInputWrapper((formData) => !formData.assignment_id);

const ShowIfAssignmentJob = createConditionalInputWrapper((formData) => !!formData.assignment_id);

const choices = [
  { id: 'regular', name: 'Regular Shift' },
  { id: 'on_call', name: 'On Call Shift' },
  { id: 'call_back', name: 'Callback Shift' },
];

const CustomSelectInput = () => {
  const { setValue } = useFormContext();

  const customOnChange = (event) => {
    const shiftType = event.target.value;
    if (shiftType === 'on_call') {
      setValue('callback_starts_time', undefined);
      setValue('callback_ends_time', undefined);
      setValue('starts_time', undefined);
      setValue('ends_time', undefined);
      setValue('approved_clock_in', undefined);
      setValue('approved_clock_out', undefined);
    } else if (shiftType === 'call_back') {
      setValue('on_call_starts_time', undefined);
      setValue('on_call_ends_time', undefined);
      setValue('starts_time', undefined);
      setValue('ends_time', undefined);
      setValue('approved_clock_in', undefined);
      setValue('approved_clock_out', undefined);
    } else {
      setValue('callback_starts_time', undefined);
      setValue('callback_ends_time', undefined);
      setValue('on_call_starts_time', undefined);
      setValue('on_call_ends_time', undefined);
    }
  };
  return <SelectInput source="shift_type" onChange={customOnChange} choices={choices} />;
};

const ReleaseToMarketplaceStrategySelect = () => {
  const { setValue } = useFormContext();

  const releaseToMarketplaceStategyOnChange = (event) => {
    const releaseStrategy = event.target.value;

    if (releaseStrategy === 'never') {
      setValue('release_audiences', ['saas']);
    } else {
      setValue('release_audiences', ['marketplace', 'saas']);
    }
  };

  const release_to_marketplace_strategy_choices = [
    { id: 'twenty_four_hours_before_starts_time', name: '24 Hours Prior To Start' },
    { id: 'instant', name: 'Release Immediately' },
    { id: 'never', name: "Don't Release" },
  ];

  return (
    <SelectInput
      validate={[required()]}
      source="release_to_marketplace_strategy"
      choices={release_to_marketplace_strategy_choices}
      onChange={releaseToMarketplaceStategyOnChange}
    />
  );
};

const JobsCreate = () => {
  const checkAccess = useCheckAccess();
  const isIrmLocation = useRef(false);

  const transformPayload = (data) => {
    let payload: Record<string, any> = {
      ...omit(['breaks'], data),
      breaks_attributes: data.breaks ?? [],
      parking_reimbursement_cents: data.parking_reimbursement_cents ?? undefined,
    };
    if (payload.assignment_id) {
      payload = omit(['surgery_type_ids', 'position_ids', 'specialty_ids'], payload);
    }
    if (!isIrmLocation.current || payload.assignment_id) {
      payload = omit(['release_to_marketplace_strategy', 'release_audiences'], payload);
    }
    if (payload.status !== 'clocked_out') {
      return payload;
    }
    return {
      ...payload,
      starts_time: payload.starts_time || payload.approved_clock_in,
      ends_time: payload.ends_time || payload.approved_clock_out,
    };
  };

  return (
    <Create redirect="show" data-testid="jobs-create" transform={transformPayload}>
      <SimpleForm defaultValues={{ shift_type: 'regular' }}>
        <Heading>Assign Job to Assignment</Heading>
        {checkAccess('create', 'Job', 'assignment_id') && (
          <ReferenceInput
            label="Assignment ID"
            source="assignment_id"
            reference="Assignment"
            sort={{ field: 'id', order: 'ASC' }}
          >
            <AutocompleteInput
              optionText={InputUtils.optionText('id')}
              data-testid="jobs_create-assignment_field"
            />
          </ReferenceInput>
        )}

        <Heading>Job Location and Time Info</Heading>
        {checkAccess('create', 'Job', 'location_id') && <LocationInput />}
        {checkAccess('create', 'Job') && (
          <ShowIfAssignmentJob>
            <CustomSelectInput />
          </ShowIfAssignmentJob>
        )}
        {checkAccess('create', 'Job') && <JobTimeInfoSection isJobCreate />}

        <Heading>Job Description</Heading>
        {checkAccess('create', 'Job') && (
          <HideIfAssignmentJob>
            <FormDataConsumer>
              {({ formData }) => {
                const { location_id, assignment_id } = formData;
                return (
                  <>
                    <PositionInput
                      multiple
                      filter={{
                        active: true,
                        search: {
                          for_locations: location_id
                            ? {
                                location_ids: [location_id],
                                for_job: !assignment_id,
                              }
                            : undefined,
                        },
                      }}
                    />
                    <SpecialtyInput
                      multiple
                      positionSource="position_ids"
                      filter={{ active: true }}
                    />
                  </>
                );
              }}
            </FormDataConsumer>
            <ReferenceArrayInput
              label="Surgery Types"
              reference="SurgeryType"
              source="surgery_type_ids"
            >
              <AutocompleteArrayInput
                optionText="display_name"
                data-testid="jobs_create-surgery_type_field"
              />
            </ReferenceArrayInput>
            <PostingFields />
            <EnumSelectInput label="Patient Population" source="patient_population" />
          </HideIfAssignmentJob>
        )}
        {checkAccess('create', 'Job', 'detail') && (
          <TextInput
            label="Detail"
            source="detail"
            multiline
            fullWidth
            data-testid="jobs_create-detail_field"
          />
        )}
        {checkAccess('create', 'Job', 'parking_reimbursement_cents') && (
          <NumberInput
            label="Parking Reimbursement Cents"
            source="parking_reimbursement_cents"
            data-testid="jobs_create-parking_reimbursement_cents_field"
          />
        )}
        {checkAccess('create', 'Job', 'offer_source') && (
          <EnumSelectInput label="Offer Source" source="offer_source" sort={false} />
        )}
        {checkAccess('create', 'Job', 'clinical_contact_account_id') && (
          <ClinicalContactInput label="Clinical Contact" source="clinical_contact_account_id" />
        )}
        <HideIfAssignmentJob>
          <FormDataConsumer>
            {({ formData }) => {
              const location = formData.location_id ? getLocation(formData.location_id) : null;
              if (location?.company?.saas_scheduling_enabled) {
                isIrmLocation.current = true;
                return <ReleaseToMarketplaceStrategySelect />;
              }
              isIrmLocation.current = false;
              return null;
            }}
          </FormDataConsumer>
        </HideIfAssignmentJob>

        <Heading>Job Status</Heading>
        {checkAccess('create', 'Job', 'status') && (
          <EnumSelectInput label="Status" source="status" />
        )}
        {checkAccess('create', 'Job', 'professional_id') && (
          <HideIfAssignmentJob>
            <ProfessionalInput />
          </HideIfAssignmentJob>
        )}
        {checkAccess('create', 'Job', 'booked_at') && (
          <FormDataConsumer>
            {({ formData }) => {
              const primary_location = formData.location_id
                ? getLocation(formData.location_id)
                : null;
              return (
                <DateTimeInput
                  time_zone={primary_location?.timezone_lookup}
                  label="Booked At"
                  source="booked_at"
                />
              );
            }}
          </FormDataConsumer>
        )}
        {checkAccess('create', 'Job', 'bonus_one_time_cents') && (
          <MoneyInput label="One Time Bonus" source="bonus_one_time_cents" />
        )}
        {checkAccess('create', 'Job', 'cancellation_fee_window') && (
          <NumberInput
            label="Cancellation Fee Window"
            source="cancellation_fee_window"
            data-testid="jobs_create-cancellation_fee_window_field"
          />
        )}
        {checkAccess('create', 'Job', 'cancellation_fee_charge_hours') && (
          <NumberInput
            label="Cancellation Fee Charge Hours"
            source="cancellation_fee_charge_hours"
            data-testid="jobs_create-cancellation_fee_charge_hours_field"
          />
        )}
        {checkAccess('create', 'Job', 'guaranteed_minimum_hours') && (
          <NumberInput
            label="Guaranteed Minimum Hours"
            source="guaranteed_minimum_hours"
            data-testid="jobs_create-guaranteed_minimum_hours_field"
          />
        )}
        {checkAccess('create', 'Job', 'override_minimum') && (
          <NullableBooleanInput
            label="Override Minimum Hours"
            source="override_minimum"
            data-testid="jobs_create-override_minimum_field"
          />
        )}

        {checkAccess('create', 'Job') && <OverrideRates />}

        {checkAccess('create', 'Job') && <JobBreaks />}

        <Heading>For Admin</Heading>
        {checkAccess('create', 'Job', 'admin_notes') && (
          <TextInput
            label="Notes"
            source="admin_notes"
            multiline
            fullWidth
            data-testid="jobs_create-notes_field"
          />
        )}
        {checkAccess('create', 'Job', 'create_multiple') && (
          <NullableBooleanInput
            label="Create Multiple"
            source="create_multiple"
            data-testid="jobs_create-create_multiple_field"
          />
        )}
        {checkAccess('create', 'Job', ['starts_date', 'ends_date']) && (
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.create_multiple ? (
                <>
                  <DaysSelect {...rest} validate={[required()]} label="Days" source="days" />
                  <br />
                  <DateInput
                    {...rest}
                    validate={[required()]}
                    label="Starts Date"
                    source="starts_date"
                  />
                  <br />
                  <DateInput
                    {...rest}
                    validate={[required()]}
                    label="Ends Date"
                    source="ends_date"
                  />
                </>
              ) : null
            }
          </FormDataConsumer>
        )}
      </SimpleForm>
    </Create>
  );
};

export default JobsCreate;
